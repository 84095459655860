import React from 'react'

const LandingPage = ({ location }) => {
  const inBrowser = () => typeof window !== 'undefined'

  if (inBrowser() && window.opener) {
    window.opener.postMessage(location.search)
    window.close()
  }
  return <p>Loading. Please wait.</p>
}

export default LandingPage
